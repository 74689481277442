import { render, staticRenderFns } from "./AddEditDriver.vue?vue&type=template&id=2986d1d9&scoped=true&"
import script from "./AddEditDriver.vue?vue&type=script&lang=js&"
export * from "./AddEditDriver.vue?vue&type=script&lang=js&"
import style0 from "./AddEditDriver.vue?vue&type=style&index=0&id=2986d1d9&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2986d1d9",
  null
  
)

export default component.exports