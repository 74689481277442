<template>
  <div class="modal-shadow saved_popup min-h-screen flex flex-col" v-if="show" @click.self="closeModal">
    <div class="inner flex-col flex p-6">
      <div class="content flex-col flex pt-6">
        <slot name="message">
          <h2>
            <span class="red_text">
                Are you sure that you want to delete this {{entity}}?
            </span>
          </h2>
        </slot>

        <slot name="button">
          <div class="border-t border-gray-200 pt-6 flex w-full">
            <div class="w-1/2 justify-center">
              <button
                  @click="closeModal"
                  class="this_button mr-3 py-3 px-3 border text_chameleon bg-green-custom hover:bg-gray-200 border-green-custom">
                Cancel
              </button>
            </div>

            <div class="w-1/2 justify-center">
              <button
                  @click="deleteItem"
                  class="this_button py-3 px-3 border text-red-500 bg-gray-200 border-gray-300 hover:text-white hover:bg-red-500 hover:border-red-500">
                Confirm
              </button>
            </div>
          </div>
        </slot>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DeleteModal",
  props: {
    entity: {
      type: String,
      default: 'vehicle',
    }
  },
  data: function () {
    return {
      show: false
    }
  },
  methods: {
    closeModal: function () {
      this.$emit('delete-close');
      this.show = false
    },
    deleteItem() {
      this.$emit('delete-select');
      this.closeModal();
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-shadow {
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.39);
}
.saved_popup {
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  @media screen and (max-width: 800px) {
    .inner {
      min-width: 330px;
    }
  }
}
.inner {
  max-width: 546px;
  width: 50vw;
  margin: auto;
  height: 200px;
  background: #FFFFFF;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.inner .content {
  align-items: center;
  gap: 20px;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
}

.inner .btn {
  width: 100px;
  height: 40px;
  margin: 0 20px;
  border-radius: 8px;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  opacity: 0.75;
}
.inner .btn:hover {
  opacity: 1;
}
.red_text {
  color: #F2374D;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
}
.grey_text {
  color: #2c3e50;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
}
.delete_btn {
  background-color: #f2374d;
}
.close_btn {
  background-color: #3d4852;
}
.this_button {
  font-size: 1.17em;
  font-weight: 700;
  border-radius: 5px;
}
.this_button_grey {
  font-size: 1.17em;
  font-weight: 700;
  border-radius: 5px;
  width: 164px;
}
</style>
