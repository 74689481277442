<template>
  <div class="page customers" v-if="!forbid">
    <div class="roll_back_line flex justify-between">
      <div @click="goBack" class="inline-block flex w-1/3 justify-start items-center go_back">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14 18L8 12L14 6" stroke="#6c757d" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        <div class="h-full label_roll_back">
          Drivers
        </div>
      </div>
    </div>

    <div class="title_line flex justify-between">
      <div class="inline-block flex w-1/3 justify-start items-center">
        <h1 class="text-left">{{ add_new ? 'Add new driver' : 'Edit driver' }}</h1>
      </div>
    </div>

    <div class="form_body flex justify-between">
      <div class="w-1/5">
        <div class="form_label text-left py-2">
          <span class="py-2 px-4">
            Driver Details
          </span>
        </div>
      </div>

      <div class="w-4/5 block">
        <div class="w-ful px-4 mb-4">
          <div class="form_row w-full">
            <label for="name" class="block text-gray-700 mb-2 text-left input_label">
              Name <span>*</span>
            </label>
            <input
                @input="toggleSaveButton"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="name"
                @click="skipErrors('name')"
                v-model="form.name"
                type="text"
                placeholder="Name">
            <p v-if="errors.name" class="text-red-500 text-xs italic text-left py-2">
              Driver name must be at least 3 characters long.
            </p>
            <p v-if="errors_msg.name && errors_msg.name.length" class="text-red-500 text-xs italic text-left py-2">
              <span v-for='error_msg of errors_msg.name' :key='error_msg'>
                {{error_msg}}
              </span>
            </p>
          </div>
        </div>

        <div class="w-full flex mb-4">
          <div class="form_row w-full md:w-1/2 p-4">
            <label for="username" class="block text-gray-700 mb-2 text-left input_label">
              Username <span>*</span>
            </label>
            <input
                @input="toggleSaveButton"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="username"
                @click="skipErrors('username')"
                v-model="form.username"
                type="text"
                placeholder="Username">
            <p v-if="errors.username" class="text-red-500 text-xs italic text-left py-2">
              Username must be at least 6 characters long
            </p>

            <p v-if="errors_msg.username && errors_msg.username.length" class="text-red-500 text-xs italic text-left py-2">
              <span v-for='error_msg of errors_msg.username' :key='error_msg'>
                {{error_msg}}</span>
            </p>
          </div>

          <div class="form_row w-full md:w-1/2 p-4">
            <label for="mobile_phone" class="block text-gray-700 mb-2 text-left input_label">
              Mobile Phone
            </label>
            <input
                @input="toggleSaveButton"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="mobile_phone"
                v-model="form.mobile_phone"
                type="text"
                placeholder="Mobile Phone">
          </div>
        </div>

        <div class="w-full flex mb-4">
          <div class="form_row w-full md:w-1/2 p-4">
            <label for="password_digest" class="block text-gray-700 mb-2 text-left input_label">
              Password <span>*</span>
            </label>
            <div class="relative">
              <input
                  @input="toggleSaveButton"
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="password_digest"
                  @click="skipErrors('password_digest')"
                  v-model="form.password_digest"
                  :type="eye_off ? 'password' : 'text'"
                  placeholder="Password">
              <div @click="eye_off = !eye_off"
                   class="eye py-1 px-2"
                   :class="eye_off ? 'eye_off' : 'eye_on'">
                <svg v-if="eye_off" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye-off"><path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path><line x1="1" y1="1" x2="23" y2="23"></line></svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
              </div>
            </div>
            <p v-if="errors.password_digest" class="text-red-500 text-xs italic text-left py-2">
              Password must be at least 8 characters long
            </p>
            <p v-if="errors_msg.password_digest && errors_msg.password_digest.length" class="text-red-500 text-xs italic text-left py-2">
              <span v-for='error_msg of errors_msg.password_digest' :key='error_msg'>
                {{error_msg}}</span>
            </p>
          </div>

          <div class="form_row w-full md:w-1/2 p-4">
            <label for="confirm_password" class="block text-gray-700 mb-2 text-left input_label">
              Confirm password <span>*</span>
            </label>
            <div class="relative">
              <input
                  @input="toggleSaveButton"
                  class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="confirm_password"
                  @click="skipErrors('confirm_password')"
                  v-model="form.confirm_password"
                  :type="eye_off ? 'password' : 'text'"
                  placeholder="Confirm password">
              <div @click="eye_off = !eye_off"
                   class="eye py-1 px-2"
                   :class="eye_off ? 'eye_off' : 'eye_on'">
                <svg v-if="eye_off" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye-off"><path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path><line x1="1" y1="1" x2="23" y2="23"></line></svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>
              </div>
            </div>
            <p v-if="errors.confirm_password" class="text-red-500 text-xs italic text-left py-2">
              This field is required
            </p>
            <p v-if="errors.password_no_match" class="text-red-500 text-xs italic text-left py-2">
              Passwords do not match
            </p>

            <p v-if="errors_msg.confirm_password && errors_msg.confirm_password.length" class="text-red-500 text-xs italic text-left py-2">
              <span v-for='error_msg of errors_msg.confirm_password' :key='error_msg'>
                {{error_msg}}
              </span>
            </p>
          </div>
        </div>

        <div class="w-full flex mb-4">
          <div class="form_row w-full md:w-1/2 p-4">
            <label for="address1" class="block text-gray-700 mb-2 text-left input_label">
              Address Line 1
            </label>
            <input
                @input="toggleSaveButton"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="address1"
                v-model="form.address1"
                type="text"
                placeholder="Address Line 1">
          </div>

          <div class="form_row w-full md:w-1/2 p-4">
            <label for="address2" class="block text-gray-700 mb-2 text-left input_label">
              Address Line 2
            </label>
            <input
                @input="toggleSaveButton"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="address2"
                v-model="form.address2"
                type="text"
                placeholder="Address Line 2">
          </div>
        </div>

        <div class="w-full flex mb-4">
          <div class="form_row w-full md:w-1/2 p-4">
            <div ref="search_countries" class="w-full text-left relative">
              <LLRADropdownDop
                  :label="'Country'"
                  :isRequired="false"
                  :defaultList="countriesList"
                  labelStyle="block text-gray-700 mb-2 text-left input_label"
                  inputStyle="block shadow appearance-none border rounded bg-white border-gray-200 text-gray-400 py-2 px-3 pr-8 leading-tight focus:text-gray-700 focus:outline-none focus:shadow-outline"
              ></LLRADropdownDop>
            </div>
          </div>

          <div class="form_row w-full md:w-1/2 p-4">
            <label for="postcode" class="block text-gray-700 mb-2 text-left input_label">
              Postcode
            </label>
            <input
                @input="toggleSaveButton"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="postcode"
                v-model="form.postcode"
                type="text"
                placeholder="Postcode">
          </div>
        </div>

        <div class="w-full flex justify-end items-center py-6 px-2">
          <button
              @click="openCancelModal"
              class="this_button mr-4 py-3 px-1 bg-gray-200 border border-gray-300">
            Cancel
          </button>

          <button
              @click="saveForm"
              :disabled="update_disable"
              :class="update_disable ? 'text-white bg-gray-400 border-gray-500' : 'text_chameleon bg-green-custom hover:bg-gray-200 border-green-custom'"
              class="this_button py-3 px-1 border">
            Save
          </button>
        </div>
      </div>
    </div>

    <DeleteModal v-on:delete-select="yesSelect" ref="delete_modal">
      <template v-slot:message>
        <h2>
            <span class="grey_text">
                Are you sure that you want to close this page?
            </span>
        </h2>
      </template>

      <template v-slot:button>
        <div class="pt-6 flex w-full">
          <div class="w-1/2 justify-center">
            <button
                @click="$refs.delete_modal.deleteItem()"
                class="this_button_grey mr-3 py-4 px-4 border text_chameleon bg-green-custom hover:bg-gray-200 border-green-custom">
              Yes
            </button>
          </div>

          <div class="w-1/2 justify-center">
            <button
                @click="$refs.delete_modal.closeModal()"
                class="this_button_grey mr-3 py-4 px-4 border text_chameleon bg-green-custom hover:bg-gray-200 border-green-custom">
              Cancel
            </button>
          </div>
        </div>
      </template>
    </DeleteModal>
  </div>
  <div class="component page" v-else>
    <OutOfLimit
      :items="'drivers'"
      :itemsProps="'drivers logins'"
      :count="allowedCount"
    />
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import {infoMessage} from "@/services/messages";
import LLRADropdownDop from "../../components/LLRADropdownDop";
import DeleteModal from "@/components/DeleteModal";
import OutOfLimit from "@/components/OutOfLimit";

export default {
  name: "AddEditDriver",
  data() {
    return {
      countriesList: [],
      filteredList: [],
      user: null,
      eye_off: true,
      add_new: true,
      forbid: false,
      allowedCount: null,
      driver: null,
      update_disable: true,
      skip_validate: [
        'mobile_phone',
        'llra_password',
        'address1',
        'address2',
        'country',
        'county',
        'postcode',
      ],
      form: {
        name: null,
        username: null,
        mobile_phone: null,
        password_digest: null,
        confirm_password: null,
        user_id: null,
        address1: null,
        address2: null,
        country: '',
        postcode: null,
      },
      errors: {
        name: false,
        username: false,
        password_digest: false,
        confirm_password: false,
        password_no_match: false,
      },
      errors_msg: {
        name: [],
        username: [],
        password_digest: [],
        confirm_password: [],
      }
    }
  },
  methods: {
    ...mapMutations([
      'setSearchSelectedOption',
    ]),
    goBack() {
      this.$router.push('/drivers');
    },
    getUser() {
      this.user = JSON.parse(localStorage.getItem('user')) || null;
    },
    openCancelModal() {
      this.$refs.delete_modal.show = true;
    },
    toggleSaveButton(val) {
      if (val.target?.value) {
        this.update_disable = false;
        this.validateUsername();
        for (let key in this.form) {
          this.errors_msg[key] = [];
          if (!this.skip_validate.includes(key) && !this.form[key]) {
            this.update_disable = true;
            return;
          }
        }

      }
    },
    validationForm() {
      this.skipErrors();

      let empty_errors = true;

      if (!this.form.name || this.form.name.length < 3) {
        this.errors.name = true;
        empty_errors = false;
      }
      if (!this.form.username || this.form.username.length < 6) {
        this.errors.username = true;
        empty_errors = false;
      }
      if (!this.form.password_digest || this.form.password_digest.length < 8) {
        this.errors.password_digest = true;
        empty_errors = false;
      }
      if (!this.form.confirm_password) {
        this.errors.confirm_password = true;
        empty_errors = false;
      }
      if (this.form.confirm_password !== this.form.password_digest) {
        this.errors.password_no_match = true;
        empty_errors = false;
      }
      this.update_disable = !empty_errors;

      return empty_errors;
    },
    skipErrors(val = null) {
      if (val) {
        this.errors[val] = false;
        if (val == 'confirm_password') {
          this.errors.password_no_match = false;
        }
      } else {
        for (let key in this.errors) {
          this.errors[key] = false;
        }
      }
    },
    saveForm() {
      if (this.validationForm()) {
        this.submitThisForm();
      }
    },
    async submitThisForm() {
      if (this.add_new) {
        await this.createNewDriver();
      } else {
        await this.updateDriver();
      }
    },
    async updateDriver() {
      const res = await this.$http.postAuth(`${this.$http.apiUrl()}/drivers/${this.driver.id}/update`, {body: this.form});

      if (res?.data?.err) {
        console.log(res?.data?.err);
        return infoMessage(
            'Driver update error.',
            this.getTopBanner ? 'error open_top_banner' :'error'
        );
      }

      infoMessage(
          `Driver info has been changed!`,
          this.getTopBanner ? 'success open_top_banner' : 'success'
      );
      this.goBack();
    },
    async createNewDriver() {
      try {
        await this.$http.postAuth(`${this.$http.apiUrl()}/drivers/create`,this.form);
      } catch (e) {
        console.error(e);
        if (e.response.data?.message) {
          for await (let item of e.response.data?.message) {
            if (item.includes('name') && !item.includes('username')) {
              this.errors_msg.name.push(item);
            }
            if (item.includes('username')) {
              this.errors_msg.username.push(item);
            }
            if (item.includes('password_digest')) {
              this.errors_msg.password_digest.push(item);
            }
            if (item.includes('confirm_password')) {
              this.errors_msg.confirm_password.push(item);
            }
          }
        }
        return infoMessage(
            'Oops. Something went wrong...',
            this.getTopBanner ? 'error open_top_banner' :'error'
        );
      }
      infoMessage(
          `Driver has been created!`,
          this.getTopBanner ? 'success open_top_banner' : 'success'
      );
      this.goBack();
    },
    async checkAddOrEdit() {
      if (this.$route.params.id && this.$route.params.id != 0) {
        this.add_new = false;
        await this.fetchDriver();
      } else {
        const isForbid = await await this.$http.getAuth(`${this.$http.apiUrl()}/drivers/forbid`);
        if (isForbid.data.forbid.allowed) {
          this.forbid = true;
          this.allowedCount = isForbid.data.forbid.count
        }
        this.add_new = true;
        await this.getUser();
        this.form.user_id = this.user.id;
      }
    },
    async fetchDriver() {
      const response = await this.$http.getAuth(`${this.$http.apiUrl()}/drivers/${this.$route.params.id}/driver`);

      this.driver = response?.data?.driver || null;

      if (!this.driver) {
        return infoMessage(
            'Oops. Something went wrong...',
            this.getTopBanner ? 'error open_top_banner' :'error'
        );
      }

      this.form = this.driver;
      this.form.password_digest = null;
      await this.updateUserCountry();
    },
    onSearchHandler(ev) {
      const val = ev?.target?.value || '';
      const search = val.trim();
      const result = this.countriesList.filter(country => {
        if (country?.name?.indexOf(search) !== -1) {
          return country;
        }
      })
      this.filteredList = result;
    },
    async getCountries() {
      try {
        const url = 'https://restcountries.com/v3.1/all?fields=name,cca2';
        const result = await this.$http.get(url);
        let countries = []
        result.data.forEach((country) => {
          countries.push({
            name: country.name.common,
            id: country.cca2
          })
        })
        const sorted = countries.slice().sort(function(a, b){
          return (a.name > b.name) ? 1 : -1;
        });
        this.countriesList = sorted;
        this.filteredList = sorted;
      } catch (e) {
        console.log(e);
        infoMessage(
            'Oops. Something went wrong...',
            this.getTopBanner ? 'error open_top_banner' :'error'
        );
      }
    },
    async updateUserCountry() {
      if (this.driver.country && this.countriesList.length) {
        this.countriesList.forEach(item => {
          if (item.id === this.driver.country) {
            this.setSearchSelectedOption(item);
          }
        });
      }
    },
    yesSelect() {
      this.goBack();
    },
    validateUsername() {
      if (this.form.username) {
        const regex = /^[a-zA-Z0-9_]*$/;
        if (!regex.test(this.form.username)) {
          this.errors_msg.username.push('Username can only contain letters, numbers and underscores');
          this.update_disable = true;
        }
      }
    }
  },
  async mounted() {
    await this.getCountries();
    await this.checkAddOrEdit();
  },
  created() {},
  computed: {
    ...mapGetters([
      'getSearchSelectedOption',
      'getTopBanner'
    ]),
  },
  components: {
    LLRADropdownDop,
    DeleteModal,
    OutOfLimit
  },
  watch: {
    getSearchSelectedOption (val) {
      if (val) {
        this.form.country = val.id;
        this.skipErrors('country');
      }
    },
    'form.username': {
      handler(val) {
        if (val) {
          this.validateUsername();
        }
      },
      deep: true
    },
  }
}
</script>

<style lang="scss" scoped>
.item {
  padding-right: 25px;
}
.actions {
  &:hover, &:active, &:focus{
    .hidden {
      display: block;
    }
    span {
      &>* {
        cursor: pointer;
      }
      &:first-child {
        &>*{
          margin-left: auto;
        }
      }
    }
  }
}
.go_back {
  cursor: pointer;
}
.title_line {
  padding: 10px 15px 22px;
}
.roll_back_line {
  color: #6c757d;
  font-size: 1.17em;
  font-weight: 700;
  padding: 10px 15px;
  width: 95%;
  margin: auto;
}
.label_roll_back {
  padding-top: 3px;
}
.active {
  background: #FFF1CE;
  border-radius: 5px;
  color: #FFA800;
}
.form_body {
  width: 95%;
  padding: 30px 15px;
  margin: auto;
}
.form_label {
  color: #22292f;
  font-size: 1.17em;
  font-weight: 600;
}
.input_label {
  color: #22292f;
  font-size: 1em;
  font-weight: 600;
  span {
    color: #28a745;
  }
}
.span_in_select {
  color: #6c757d !important;
}
.input_span {
  position: relative;
}
.units {
  position: absolute;
  top: 3px;
  right: 15px;
}
.this_button {
  font-size: 1.17em;
  font-weight: 700;
  border-radius: 10px;
  width: 100px;
}
.eye {
  position: absolute;
  top: 0;
  right: 0;
}
.eye_off {
  color: #6c757d;
}
.eye_on {
  color: #28a745;
}
.this_button {
  font-size: 1.17em;
  font-weight: 700;
  border-radius: 5px;
  width: 64px;
}
</style>
